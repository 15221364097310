import React from "react"
import {Container,Button,Navbar,Nav} from 'react-bootstrap';
import { Link, navigate } from "gatsby"
import VideoImg1 from "../../assests/images/video1.jpg"; 
import NavLink from  "../../components/Preval_Presentation/NavLink"; 
import Header from  "../../components/Common/Header"; 

const PresentationPage = () => {
    
    return <div className="pre-wrapper">
      
      <Header />

      <div className="contact-wrapper intro_section list_my_property">
          <Container>
            <div className="contact-wrap">
                <div className="contact-content">
                    <h2>How soon would you like to sell your home with Property Launch?</h2>
                    <p class="fig-content">Click below to list your property and select the best dates for your Property Launch</p>
                    <Button variant="primary" type="submit">list my property</Button>
                    <Button variant="secondary" type="submit">any questions?</Button>
                </div>
                {/* contact-content */} 

                <div className="employment-right">
                    <figure>
                        <img src={VideoImg1} alt="video-img" />
                    </figure>

                    <div className="content-block">
                        <h3>Amelia Adams</h3>
                        <Link to="#">07891 219 384</Link>
                    </div>
                </div>
                {/* video-wrap */}
            </div>
            {/* contact-wrap */}
          </Container>                     
      </div>
      {/* contact-wrapper */}
      
      <NavLink tab_name="Intro" />

    </div>
    // pre-wrapper
}

export default PresentationPage